var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-full grid gap-x-6 grid-cols-12" },
    [
      _c("label", { staticClass: "col-span-2 text-right" }, [
        _c("p", { staticClass: "text-sm" }, [_vm._v(_vm._s(_vm.label))]),
        _c("p", { staticClass: "text-xs" }, [_vm._v(_vm._s(_vm.label2))]),
      ]),
      _c("ValidationProvider", {
        class: "col-span-4 relative",
        attrs: {
          tag: "div",
          vid: _vm.translationFromVid,
          rules: _vm.rules,
          name: _vm.label,
          mode: _vm.mode,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              return [
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.sublabel,
                        expression: "sublabel",
                      },
                    ],
                    staticClass: "text-xxs text-left absolute sub-label",
                  },
                  [_vm._v(_vm._s(_vm.sublabel))]
                ),
                _c("div", { staticClass: "flex justify-between mb-1" }, [
                  _c("span", { staticClass: "text-xxs" }, [
                    _vm._v(_vm._s(_vm.getFromTranslationLanguage())),
                  ]),
                  _c("span", { staticClass: "text-xxs" }, [
                    _vm._v(
                      _vm._s(_vm.textCount.from) +
                        " " +
                        _vm._s(_vm.$t("pages.commons.letter_count"))
                    ),
                  ]),
                ]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.fromInputValue,
                      expression: "fromInputValue",
                    },
                  ],
                  staticClass: "input_text w-full h-40",
                  class: { "bg-red-200 border-red-600": errors.length },
                  attrs: { type: _vm.type },
                  domProps: { value: _vm.fromInputValue },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.fromInputValue = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: errors.length,
                        expression: "errors.length",
                      },
                    ],
                    staticClass: "text-xxs text-left text-red-600 absolute",
                  },
                  [_vm._v(_vm._s(errors[0]))]
                ),
              ]
            },
          },
        ]),
      }),
      _c("div", { staticClass: "col-span-2 py-8 text-center" }, [
        _c("img", {
          staticClass: "w-8 h-8 mx-auto",
          attrs: { src: require("@/assets/image/convert_icon_grey.svg") },
        }),
        _c(
          "button",
          {
            staticClass:
              "px-3 py-2 mt-2 bg-light-yellow hover:bg-light-yellow-lighter focus:bg-light-yellow-darker",
            on: { click: _vm.translate },
          },
          [_vm._v(" " + _vm._s(_vm.$t("commons.fields.translate")) + " ")]
        ),
      ]),
      _c("ValidationProvider", {
        class: "col-span-4 relative",
        attrs: {
          tag: "div",
          vid: _vm.translationToVid,
          rules: _vm.rules2,
          name: _vm.label,
          mode: _vm.mode,
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (ref) {
              var errors = ref.errors
              return [
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.sublabel,
                        expression: "sublabel",
                      },
                    ],
                    staticClass: "text-xxs text-left absolute sub-label",
                  },
                  [_vm._v(_vm._s(_vm.sublabel))]
                ),
                _c("div", { staticClass: "flex justify-between mb-1" }, [
                  _c("span", { staticClass: "text-xxs" }, [
                    _vm._v(_vm._s(_vm.getToTranslationLanguage())),
                  ]),
                  _c("span", { staticClass: "text-xxs" }, [
                    _vm._v(
                      _vm._s(_vm.textCount.to) +
                        " " +
                        _vm._s(_vm.$t("pages.commons.letter_count"))
                    ),
                  ]),
                ]),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.toInputValue,
                      expression: "toInputValue",
                    },
                  ],
                  staticClass: "input_text w-full h-40",
                  class: { "bg-red-200 border-red-600": errors.length },
                  attrs: { type: _vm.type },
                  domProps: { value: _vm.toInputValue },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.toInputValue = $event.target.value
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: errors.length,
                        expression: "errors.length",
                      },
                    ],
                    staticClass: "text-xxs text-left text-red-600 absolute",
                  },
                  [_vm._v(_vm._s(errors[0]))]
                ),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }